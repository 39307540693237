<template>
  <div class="hold-hold-transition">
    <div class="wrapper">
      <div class="content-wrapper">
        <!-- Encabezados -->
        <section class="content-header">
          <div class="container-fluid">
            <div class="row">
              <!-- titulo -->
              <div class="col-sm-6">
                <h5 class="mb-0">Formatos Inspección</h5>
              </div>
              <!-- /.titulo -->
              <div class="col-sm-6">
                <ol class="breadcrumb float-sm-right">
                  <li class="breadcrumb-item">
                    <router-link to="/">Home</router-link>
                  </li>
                  <li class="breadcrumb-item active">Operación</li>
                  <li class="breadcrumb-item active">Formato Inspección</li>
                  <li class="breadcrumb-item active">
                    Gestion Formatos Inspección
                  </li>
                </ol>
              </div>
            </div>
          </div>
        </section>
        <!-- Cuerpo -->
        <section class="content">
          <div class="container-fluid">
            <div class="card">
              <div class="card-header pt-2 pb-2">
                <div class="col-3 pt-2 pb-2">
                  <h5 class="mb-0">Formato De Inspección</h5>
                </div>
              </div>
              <div class="card-body">
                <div class="row">
                  <!-- Campo Nombre form -->
                  <div class="col-md-3 col-sm-12">
                    <div class="form-group">
                      <label for="nombre">Nombre</label>
                      <input
                        type="text"
                        class="form-control form-control-sm"
                        id="razon_social"
                        v-model="form.nombre"
                        :class="
                          $v.form.nombre.$invalid ? 'is-invalid' : 'is-valid'
                        "
                      />
                    </div>
                  </div>
                  <!-- /.Campo Nombre form -->
                  <!-- Campo Estado form -->
                  <div class="col-md-2">
                    <div class="form-group">
                      <label for="estado">Estado</label>
                      <select
                        class="form-control form-control-sm"
                        v-model="form.estado"
                        :class="
                          $v.form.estado.$invalid ? 'is-invalid' : 'is-valid'
                        "
                      >
                        <option value="">Seleccione...</option>
                        <option
                          v-for="estado in listasForms.estados"
                          :key="estado.id"
                          :value="estado.id"
                        >
                          {{ estado.descripcion }}
                        </option>
                      </select>
                    </div>
                  </div>
                  <!-- Campo Estado form -->
                  <!-- Campo linea de negocio-->
                  <div class="col-md-3">
                    <div class="form-group">
                      <label for="linea_negocio">Línea De Negocio</label>
                      <select
                        class="form-control form-control-sm"
                        v-model="form.linea_negocio_id"
                        :class="
                          $v.form.linea_negocio_id.$invalid
                            ? 'is-invalid'
                            : 'is-valid'
                        "
                      >
                        <option value="">Seleccione...</option>
                        <option
                          v-for="linea in listasForms.lineas_negocio"
                          :key="linea.id"
                          :value="linea.id"
                        >
                          {{ linea.nombre }}
                        </option>
                      </select>
                    </div>
                  </div>
                  <!-- Campo linea de negocio-->
                  <!-- Campo tipo operación-->
                  <div class="col-md-2" v-if="form.linea_negocio_id == 2">
                    <div class="form-group">
                      <label for="tipo_operacion">Tipo Operación</label>
                      <select
                        class="form-control form-control-sm"
                        v-model="form.tipo_operacion"
                        :class="
                          $v.form.tipo_operacion.$invalid
                            ? 'is-invalid'
                            : 'is-valid'
                        "
                      >
                        <option value="">Seleccione...</option>
                        <option value="1">Cargue</option>
                        <option value="2">Descargue</option>
                      </select>
                    </div>
                  </div>
                  <!-- Campo tipo operación -->
                  <!-- Campo check pre operacion -->
                  <div class="col-md-2 col-sm-12">
                    <div class="form-group" style="margin-top: 30px">
                      <div class="form-check">
                        <input
                          type="checkbox"
                          class="form-check-input"
                          id="exampleCheck1"
                          v-model="form.pre_operacional"
                        />
                        <label>Pre - Operacional</label>
                      </div>
                    </div>
                  </div>
                  <!-- /. Campo check pre operacion -->
                </div>
                <div class="row">
                  <div class="col-lg-6 col-md-6 col-sm-6" v-if="form.id">
                    <h5>Secciones</h5>
                  </div>
                  <!-- Boton de agregar Seccion -->
                  <div
                    class="col-lg-6 col-md-6 col-sm-6 text-right"
                    v-if="form.id"
                  >
                    <button
                      data-target="#Modal_lineas"
                      data-toggle="modal"
                      class="btn btn-info"
                      v-on:click="cleanerModal('POST', 'Crear')"
                    >
                      Agregar Sección
                    </button>
                  </div>
                  <div class="col-lg-12 col-md-6 col-sm-12"></div>
                  <div
                    class="col-lg-12 col-md-12 col-sm-12"
                    style="padding-top: 15px"
                  >
                    <div class="panel">
                      <div class="panel-heading">
                        <div class="accordion" id="accordionExample">
                          <div
                            class="card"
                            v-for="item in secciones"
                            :key="item.id"
                          >
                            <div
                              class="card-header"
                              v-bind:id="'heading' + item.id"
                              style="background-color: #e6e6e6"
                            >
                              <div class="row">
                                <div class="col-lg-6 col-md-6 col-sm-6">
                                  <h5 class="mb-0">
                                    <button
                                      class="btn btn-link collapsed"
                                      type="button"
                                      data-toggle="collapse"
                                      v-bind:data-target="'#collapse' + item.id"
                                      aria-expanded="false"
                                      v-on:click="loadItems(item.id)"
                                      v-bind:aria-controls="
                                        'collapse' + item.id
                                      "
                                    >
                                      <u
                                        ><strong>{{ item.nombre }}</strong></u
                                      >
                                    </button>
                                  </h5>
                                </div>
                                <div class="col-lg-6 col-md-6 col-sm-6">
                                  <div class="btn-group float-right">
                                    <button
                                      class="btn btn-sm btn-danger"
                                      v-on:click="destroySeccion(item.id)"
                                    >
                                      <i class="fas fa-trash"></i>
                                    </button>
                                    <button
                                      data-target="#Modal_lineas"
                                      data-toggle="modal"
                                      class="btn btn-sm bg-navy"
                                      v-on:click="
                                        cleanerModal('PUT', 'Editar', item.id)
                                      "
                                    >
                                      <i class="fas fa-edit"></i>
                                    </button>
                                  </div>
                                </div>
                              </div>
                              <!-- Btn de ediccion de la seccion -->
                            </div>
                            <div
                              v-bind:id="'collapse' + item.id"
                              class="collapse"
                              v-bind:aria-labelledby="'heading' + item.id"
                              data-parent="#accordionExample"
                            >
                              <div class="card-body">
                                <div class="row">
                                  <!-- Formualario ingreso de items -->
                                  <div class="col-lg-12 col-md-12 col-sm-12">
                                    <button
                                      type="button"
                                      class="btn btn-primary"
                                      style="float: right"
                                      data-toggle="modal"
                                      data-target="#Modal_Items"
                                      v-on:click="limpiarModalItems()"
                                    >
                                      Agregar Item
                                    </button>
                                  </div>
                                  <div class="col-lg-12 col-md-12 col-sm-12">
                                    <br />
                                    <table class="table table-sm table-hover">
                                      <thead class="thead-dark">
                                        <tr>
                                          <th scope="col"></th>
                                          <th scope="col">Nombre</th>
                                          <th scope="col">Tipo de control</th>
                                          <th scope="col">
                                            Tamaño del control
                                          </th>
                                          <th scope="col">Relevante</th>
                                          <th scope="col">
                                            Calif. Mantenimiento
                                          </th>
                                          <th scope="col">Calif. HSE</th>
                                          <th scope="col">Acciones</th>
                                          <th scope="col">Orden</th>
                                        </tr>
                                      </thead>
                                      <tbody>
                                        <tr
                                          v-for="(itemInspeccion,
                                          index) in itemsSeccion"
                                          :key="itemInspeccion.id"
                                        >
                                          <th>
                                            {{ index + 1 }}
                                          </th>
                                          <td>{{ itemInspeccion.nombre }}</td>
                                          <td>
                                            {{ itemInspeccion.nTipoControl }}
                                          </td>
                                          <td>
                                            {{ itemInspeccion.tamano_control }}
                                          </td>
                                          <td class="text-center">
                                            <i
                                              :class="
                                                itemInspeccion.pivot
                                                  .relevante == 1
                                                  ? 'fa fa-check text-success'
                                                  : 'fa fa-times-circle text-danger'
                                              "
                                            ></i>
                                          </td>
                                          <td class="text-center">
                                            <i
                                              :class="
                                                itemInspeccion.calificacion_novedades_mantenimiento ==
                                                1
                                                  ? 'fa fa-check text-success'
                                                  : 'fa fa-times-circle text-danger'
                                              "
                                            ></i>
                                          </td>
                                          <td class="text-center">
                                            <i
                                              :class="
                                                itemInspeccion.calificacion_novedades_hse ==
                                                1
                                                  ? 'fa fa-check text-success'
                                                  : 'fa fa-times-circle text-danger'
                                              "
                                            ></i>
                                          </td>
                                          <td>
                                            <div class="btn-group float-right">
                                              <button
                                                data-toggle="modal"
                                                data-target="#Modal_LineasNegocio"
                                                class="btn btn-sm btn-secondary"
                                                style="float: right"
                                                @click="
                                                  getItemLineasNegocio(
                                                    itemInspeccion.nombre,
                                                    itemInspeccion.pivot.id
                                                  )
                                                "
                                              >
                                                <i class="fas fa-eye"></i>
                                              </button>
                                              <button
                                                type="button"
                                                class="btn btn-sm bg-navy"
                                                data-toggle="modal"
                                                data-target="#modal-form-edit"
                                                @click="
                                                  editItemsSeccion(
                                                    1,
                                                    itemInspeccion.id,
                                                    itemInspeccion.pivot.id
                                                  )
                                                "
                                              >
                                                <i class="fas fa-edit"></i>
                                              </button>
                                              <button
                                                class="btn btn-sm btn-danger"
                                                style="float: right"
                                                @click="
                                                  saveDestroyItems(
                                                    2,
                                                    itemInspeccion.id,
                                                    itemInspeccion.pivot.id
                                                  )
                                                "
                                              >
                                                <i class="fas fa-trash"></i>
                                              </button>
                                            </div>
                                          </td>
                                          <td>
                                            <div class="btn-group float-right">
                                              <button
                                                class="btn btn-sm btn-success"
                                                v-if="index + 1 != 1"
                                                @click="subirOrden(index)"
                                              >
                                                <i
                                                  class="fas fa-arrow-up"
                                                ></i></button
                                              ><button
                                                class="btn btn-sm btn-danger"
                                                v-if="
                                                  index + 1 !=
                                                    itemsSeccion.length
                                                "
                                                @click="bajarOrden(index)"
                                              >
                                                <i
                                                  class="fas fa-arrow-down"
                                                ></i>
                                              </button>
                                            </div>
                                          </td>
                                        </tr>
                                      </tbody>
                                    </table>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="card-footer">
                <div class="row">
                  <button
                    class="btn bg-primary col-md-1"
                    v-show="!$v.form.$invalid"
                    @click="save_formato()"
                  >
                    <i class="fas fa-paper-plane"></i><br />Guardar
                  </button>

                  <button class="btn bg-secondary col-md-1" @click="back()">
                    <i class="fas fa-reply"></i><br />Volver
                  </button>
                </div>
              </div>
            </div>
          </div>
        </section>
        <!-- Modal de agregar y editar Seccion-->
        <div class="modal fade" id="Modal_lineas">
          <div class="modal-dialog modal-md">
            <div class="modal-content">
              <div class="modal-header bg-frontera-top-left pt-2 pb-2">
                <h4 class="modal-title text-white">{{ nom_accion }} Sección</h4>
                <button
                  type="button"
                  class="close text-white"
                  data-dismiss="modal"
                  aria-label="Close"
                  id="close-modal"
                  ref="ModalLineas"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <!-- Cuerpo del modal -->
              <div class="modal-body">
                <div class="row">
                  <!-- campo Nombre -->
                  <div class="col-lg-12">
                    <div class="form-group">
                      <label for="descripcion">Nombre Sección</label>
                      <input
                        type="text"
                        class="form-control form-control-sm"
                        id="nombre"
                        placeholder="Nombre Seccion"
                        v-model="form_Seccion.nombre"
                        :class="
                          $v.form_Seccion.nombre.$invalid
                            ? 'is-invalid'
                            : 'is-valid'
                        "
                      />
                    </div>
                  </div>
                  <!-- /. campo Nombre -->
                </div>
              </div>
              <div class="modal-footer justify-content-between">
                <button
                  type="button"
                  class="btn btn-primary"
                  v-show="!$v.form_Seccion.$invalid"
                  v-on:click="save_seccion()"
                >
                  Guardar
                </button>
              </div>
            </div>
            <!-- /.modal-content -->
          </div>
          <!-- /.modal-dialog -->
        </div>
        <!-- Modal Items de inspeccion -->
        <!-- Modal -->
        <div
          class="modal fade"
          id="Modal_Items"
          tabindex="-1"
          role="dialog"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div class="modal-dialog" role="document">
            <div class="modal-content">
              <div class="modal-header bg-frontera-top-left pt-2 pb-2">
                <h5 class="modal-title text-white" id="exampleModalLabel">
                  Agregar Items de inspección
                </h5>
                <button
                  type="button"
                  class="close"
                  data-dismiss="modal"
                  aria-label="Close"
                  ref="modalDialog"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div class="modal-body">
                <form>
                  <div class="form-group">
                    <label for="exampleInputEmail1">Item de inspección</label>
                    <v-select
                      :class="$store.getters.getDarkMode ? 'dark-vselect' : ''"
                      class="style-chooser"
                      label="nombre"
                      :options="arrayitems"
                      placeholder="Buscar items..."
                      @input="getDatosItems"
                      v-model="nombre_Item"
                    >
                    </v-select>
                  </div>
                  <div class="form-check">
                    <input
                      type="checkbox"
                      class="form-check-input"
                      id="exampleCheck1"
                      v-model="relevante"
                    />
                    <label class="form-check-label" for="exampleCheck1"
                      >Relevante</label
                    >
                    <small id="emailHelp" class="form-text text-muted"
                      >Al marcar como relevante, al gestionar la inspección se
                      generará proceso de autorización</small
                    >
                  </div>
                  <br />
                  <!-- div de relevancia por operacion -->
                  <div
                    class="form-group"
                    style="margin-left: 50px"
                    v-if="relevante"
                  >
                    <div
                      class="form-check"
                      v-for="item in listasForms.lineas_negocio"
                      :key="item.id"
                    >
                      <label class="form-check-label">
                        <input
                          type="checkbox"
                          class="form-check-input"
                          :value="item.id"
                          v-model="arrayrelevantelineas"
                        />{{ item.nombre }}
                      </label>
                    </div>
                    <small id="emailHelp" class="form-text text-muted"
                      >Seleccione las lineas para las cuales sera
                      relevante</small
                    >
                  </div>
                </form>
              </div>
              <div class="modal-footer">
                <button
                  type="button"
                  class="btn btn-secondary"
                  data-dismiss="modal"
                >
                  Cerrar
                </button>
                <button
                  type="button"
                  class="btn btn-primary"
                  v-if="nombre_Item != null"
                  v-on:click="saveDestroyItems(1, null)"
                >
                  Guardar
                </button>
              </div>
            </div>
          </div>
        </div>
        <!-- Modal Líneas de negocio relevantes -->
        <!-- Modal -->

        <div
          class="modal fade"
          id="Modal_LineasNegocio"
          tabindex="-1"
          role="dialog"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div class="modal-dialog" role="document">
            <div class="modal-content">
              <div class="modal-header bg-frontera-top-left pt-2 pb-2">
                <h5 class="modal-title text-white" id="exampleModalLabel">
                  Líneas de Negocio - {{ modalLineasNegocios.title }}
                </h5>
                <button
                  type="button"
                  class="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div class="modal-body">
                <div
                  class="form-group"
                  v-if="modalLineasNegocios.lineas_negocio"
                >
                  <table
                    class="table table-sm table-hover"
                    v-if="modalLineasNegocios.lineas_negocio"
                  >
                    <thead class="thead-dark">
                      <tr>
                        <th class="text-center" colspan="2">
                          Líneas de negocio
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr
                        v-for="linea_negocio in modalLineasNegocios.lineas_negocio"
                        :key="linea_negocio.id"
                      >
                        <td class="text-center">
                          <i class="fa fa-check text-success"></i>
                        </td>
                        <td>{{ linea_negocio.nombre }}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <div class="form-group" v-else>
                  <div class="alert alert-warning col-12">
                    <h5>
                      <i class="icon fas fa-exclamation-triangle"></i>
                      Atención!
                    </h5>
                    No se asignaron líneas de negocio en el item
                    <strong>{{ modalLineasNegocios.title }}</strong
                    >.
                  </div>
                </div>
              </div>
              <div class="modal-footer">
                <button
                  type="button"
                  class="btn btn-secondary"
                  data-dismiss="modal"
                >
                  Cerrar
                </button>
              </div>
            </div>
          </div>
        </div>
        <!-- Modal editar relevante -->
        <!-- Modal -->
        <div
          class="modal fade"
          id="modal-form-edit"
          tabindex="-1"
          role="dialog"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div class="modal-dialog" role="document">
            <div class="modal-content">
              <div class="modal-header bg-frontera-top-left pt-2 pb-2">
                <h5 class="modal-title text-white" id="exampleModalLabel">
                  Editar
                </h5>
                <button
                  type="button"
                  class="close"
                  data-dismiss="modal"
                  aria-label="Close"
                  ref="modalDialog"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div class="modal-body">
                <form>
                  <div class="form-check">
                    <input
                      type="checkbox"
                      class="form-check-input"
                      id="exampleCheck2"
                      v-model="form_Edit.relevant"
                    />
                    <label class="form-check-label" for="exampleCheck2"
                      >Relevante</label
                    >
                    <small id="emailHelp" class="form-text text-muted"
                      >Al marcar como relevante, al gestionar la inspección se
                      generará proceso de autorización</small
                    >
                  </div>
                  <br />
                  <!-- div de relevancia por operacion -->
                  <div
                    class="form-group"
                    style="margin-left: 50px"
                    v-if="form_Edit.relevant"
                  >
                    <div
                      class="form-check"
                      v-for="item in listasForms.lineas_negocio"
                      :key="item.id"
                    >
                      <label class="form-check-label">
                        <input
                          type="checkbox"
                          class="form-check-input"
                          :value="item.id"
                          v-model="form_Edit.linea_negocio1"
                        />{{ item.nombre }}
                      </label>
                    </div>
                    <small id="emailHelp" class="form-text text-muted"
                      >Seleccione las lineas para las cuales sera
                      relevante</small
                    >
                  </div>
                </form>
              </div>
              <div class="modal-footer">
                <button
                  type="button"
                  class="btn btn-secondary"
                  data-dismiss="modal"
                >
                  Cerrar
                </button>
                <button
                  type="button"
                  class="btn btn-primary"
                  v-on:click="editItemsSeccion(2, null)"
                >
                  Guardar
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
/* axios */
import axios from "axios";
/* validaciones */
import { required } from "vuelidate/lib/validators";
import vSelect from "vue-select";
export default {
  name: "FormatoInspeccionForm",
  components: {
    vSelect,
  },
  data() {
    return {
      accion: "",
      form: {},
      form_Seccion: {},
      form_Edit: {
        relevant: false,
        linea_negocio1: [],
        item_id1: false,
        seccion_id1: false,
        secciones_has_items_inspeccion_id1: false,
        array_negocio: [],
      },
      secciones: {},
      itemsSeccion: {},
      itemsEdit: {},
      arrayitems: [],
      arrayrelevantelineas: [],

      Sub_calificacion: {
        mantenimiento: false,
        hse: false,
      },
      idItem: "",
      nombre_Item: "",
      ItemInfo: {},
      relevante: "",
      activesection: "",
      descripcion: "",
      metodo: "",
      nom_accion: "",
      metodo_secciones: "",
      prueba: {},
      /* Areglos selects */
      listasForms: {
        estados: [],
        lineas_negocio: [],
      },
      modalLineasNegocios: {
        title: null,
        lineas_negocio: [],
      },
    };
  },
  validations() {
    if (this.form.linea_negocio_id == 2) {
      return {
        form: {
          nombre: {
            required,
          },
          estado: {
            required,
          },
          linea_negocio_id: {
            required,
          },
          tipo_operacion: {
            required,
          },
        },
        form_Seccion: {
          nombre: {
            required,
          },
        },
      };
    } else {
      return {
        form: {
          nombre: {
            required,
          },
          estado: {
            required,
          },
          linea_negocio_id: {
            required,
          },
        },
        form_Seccion: {
          nombre: {
            required,
          },
        },
      };
    }
  },
  methods: {
    getEstados() {
      axios.get("/api/lista/1").then((response) => {
        this.listasForms.estados = response.data;
      });
    },
    getLineasNegocio() {
      axios.get("/api/admin/lineasNegocios/lista").then((response) => {
        this.listasForms.lineas_negocio = response.data;
      });
    },
    back() {
      return this.$router.replace("/Admin/formatosInspeccion");
    },
    init() {
      this.accion = this.$route.params.accion;
      if (this.$route.params.accion == "Editar") {
        this.form = this.$route.params.data_edit;
        this.metodo = "PUT";
      } else {
        this.metodo = "POST";
      }
    },
    /* Funciones Secciones */
    cleanerModal(method, nom_accion, seccion) {
      if (nom_accion == "Crear") {
        this.nom_accion = nom_accion;
        this.metodo_secciones = method;
        this.form_Seccion = {};
      } else {
        this.nom_accion = nom_accion;
        this.metodo_secciones = method;
        var i;
        for (i = 0; i < this.secciones.length; i++) {
          if (this.secciones[i].id == seccion) {
            this.form_Seccion = this.secciones[i];
          }
        }
      }
    },
    save_seccion() {
      if (!this.$v.form_Seccion.$invalid) {
        this.form_Seccion.formato_inspeccion_id = this.form.id;
        axios({
          method: this.metodo_secciones,
          url: "/api/admin/seccionInspeccion",
          data: this.form_Seccion,
        })
          .then((response) => {
            this.$refs.ModalLineas.click();
            this.$swal({
              icon: "success",
              title: "la seccion se guardo exitosamente...",
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true,
            });
            this.LoadSections();
          })
          .catch((e) => {
            this.$swal({
              icon: "error",
              title: "Ocurrio un error, vuelva a intentarlo..." + e,
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true,
            });
          });
      }
    },
    save_formato() {
      if (!this.$v.form.$invalid) {
        axios({
          method: this.metodo,
          url: "/api/admin/formatosInspeccion",
          data: this.form,
        })
          .then((response) => {
            this.$swal({
              icon: "success",
              title: "la seccion se guardo exitosamente...",
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true,
            });
            this.LoadSections();
            this.form = response.data;
          })
          .catch((e) => {
            this.$swal({
              icon: "error",
              title: "Ocurrio un error, vuelva a intentarlo..." + e,
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true,
            });
          });
      }
    },
    LoadSections() {
      axios
        .get("/api/admin/seccionInspeccion", {
          params: {
            formato_inspeccion_id: this.form.id,
          },
        })
        .then((response) => {
          this.secciones = response.data;
        });
      this.llenarSelectItems();
    },
    destroySeccion(idSeccion) {
      this.$swal({
        title: "Esta seguro la seccion de esta Inspeccion?",
        text: "Los cambios no se pueden revertir!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Si, Eliminar!",
        cancelButtonText: "Cancelar",
      }).then((result) => {
        // <--
        if (result.value) {
          axios.delete("/api/admin/seccionInspeccion/" + idSeccion).then(() => {
            this.LoadSections();
            this.$swal({
              icon: "success",
              title: "Se elimino la seccion de manera exitosamente...",
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 4000,
              timerProgressBar: true,
            });
          });
        }
      });
    },
    /* Funciones Items */
    loadItems(idSeccion) {
      this.activesection = idSeccion;
      axios
        .get("/api/admin/seccionInspeccion/detalleItemsSeccion", {
          params: {
            seccion_id: idSeccion,
          },
        })
        .then((response) => {
          this.itemsSeccion = response.data;
          this.ordenarItems(response.data);
        });
    },
    editItemsSeccion(event, id_item, secciones_has_items_inspeccion_id) {
      let relevante = "";
      if (event == 1) {
        this.ItemInfo = {
          seccion_id: this.activesection,
          item_id: id_item,
          secciones_has_items_inspeccion_id,
        };
        axios({
          method: "GET",
          url: "/api/admin/seccionInspeccion/editItemSeccion",
          params: this.ItemInfo,
        })
          .then((response) => {
            this.itemsEdit = response.data.arregloItems;
            this.form_Edit.relevant = this.itemsEdit[0].relevant;
            this.form_Edit.linea_negocio1 = this.itemsEdit[0].linea_negocio;
            this.form_Edit.item_id1 = this.itemsEdit[0].item_id;
            this.form_Edit.seccion_id1 = this.itemsEdit[0].seccion_id;
            this.form_Edit.secciones_has_items_inspeccion_id1 = this.itemsEdit[0].secciones_has_items_inspeccion_id;
          })
          .catch((e) => {
            this.$swal({
              icon: "error",
              title: "Ocurrio un error, vuelva a intentarlo..." + e,
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true,
            });
          });
      } else {
        if (this.form_Edit.relevant) {
          relevante = 1;
        } else {
          relevante = 0;
        }

        this.ItemInfo = {
          seccion_id: this.activesection,
          orden: this.itemsSeccion.length + 1,
          item_id: this.form_Edit.item_id1,
          relevante: relevante,
          secciones_has_items_inspeccion_id: this.form_Edit
            .secciones_has_items_inspeccion_id1,
          relevante_lineas: this.form_Edit.linea_negocio1,
        };

        axios({
          method: "PUT",
          url: "/api/admin/seccionInspeccion/updateItemSeccion",
          data: this.ItemInfo,
        })
          .then(() => {
            this.$refs.modalDialog.click();
            this.$swal({
              icon: "success",
              title: "Se Agregó el Item de Inspección de manera correcta",
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true,
            });
            this.nombre_Item = "";
            (this.relevante = ""), this.loadItems(this.activesection);
          })
          .catch((e) => {
            this.$swal({
              icon: "error",
              title: "Ocurrio un error, vuelva a intentarlo..." + e,
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true,
            });
          });
      }
    },
    ordenarItems(items) {
      let flag_ordenar = false;
      let params = [];
      items.forEach((item, index) => {
        params.push({
          seccion_id: item.pivot.seccion_id,
          item_id: item.pivot.item_id,
          orden: index + 1,
        });
        if (!item.pivot.orden) {
          flag_ordenar = true;
        }
      });
      if (flag_ordenar) {
        this.actualizaOrden(params);
      }
    },
    saveDestroyItems(evento, id_item, secciones_has_items_inspeccion_id) {
      //funcion que realizar el guardardo de los items
      let bandera = false;
      let relevante = "";
      // en caso del que el evento sea agregar un nuevo Item
      if (evento == 1) {
        //funcion que valida si el item ya se encuentra asociado
        for (let i = 0; i < this.itemsSeccion.length; i++) {
          if (this.itemsSeccion[i].id == this.nombre_Item.id) {
            this.$swal({
              icon: "error",
              title:
                "El item seleccionado ya se encuentra asignado a la sección",
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 4000,
              timerProgressBar: true,
            });
            bandera = true;
          }
        }

        if (!bandera) {
          // Se valida si es relevante o no relevante
          if (this.relevante) {
            relevante = 1;
          } else {
            relevante = 0;
          }

          this.ItemInfo = {
            seccion_id: this.activesection,
            item_id: this.nombre_Item.id,
            orden: this.itemsSeccion.length + 1,
            relevante: relevante,
            evento: evento,
            relevante_lineas: this.arrayrelevantelineas,
          };

          axios({
            method: "PUT",
            url: "/api/admin/seccionInspeccion/assingOrRevokeItem",
            data: this.ItemInfo,
          })
            .then(() => {
              this.$refs.modalDialog.click();
              this.$swal({
                icon: "success",
                title: "Se Agregó el Item de Inspección de manera correcta",
                toast: true,
                position: "top-end",
                showConfirmButton: false,
                timer: 3000,
                timerProgressBar: true,
              });
              this.nombre_Item = "";
              (this.relevante = ""), this.loadItems(this.activesection);
            })
            .catch((e) => {
              this.$swal({
                icon: "error",
                title: "Ocurrio un error, vuelva a intentarlo..." + e,
                toast: true,
                position: "top-end",
                showConfirmButton: false,
                timer: 3000,
                timerProgressBar: true,
              });
            });
        }
      } else {
        this.ItemInfo = {
          seccion_id: this.activesection,
          item_id: id_item,
          secciones_has_items_inspeccion_id,
        };
        axios({
          method: "PUT",
          url: "/api/admin/seccionInspeccion/assingOrRevokeItem",
          data: this.ItemInfo,
        })
          .then(() => {
            this.$swal({
              icon: "success",
              title: "Se eliminó el item de manera correcta",
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 4000,
              timerProgressBar: true,
            });
            this.nombre_Item = "";
            (this.relevante = ""), this.loadItems(this.activesection);
          })
          .catch((e) => {
            if (e.response.status == 500) {
              if (
                e.response.data.message.includes(
                  "SQLSTATE[23000]: Integrity constraint violation"
                )
              ) {
                this.$swal({
                  icon: "error",
                  title:
                    "El ítem a eliminar ya esta siendo usado dentro de una inspección.",
                  toast: true,
                  position: "top-end",
                  showConfirmButton: false,
                  timer: 5000,
                  timerProgressBar: true,
                });
              } else {
                this.$swal({
                  icon: "error",
                  title: "Ocurrió un error, vuelva a intentarlo..." + e,
                  toast: true,
                  position: "top-end",
                  showConfirmButton: false,
                  timer: 4000,
                  timerProgressBar: true,
                });
              }
            }
          });
      }
    },
    getDatosItems(val1) {
      let me = this;
      me.loading = true;
      me.idItem = val1.id;
    },
    getItemLineasNegocio(item_nombre, secciones_has_items_inspeccion_id) {
      this.modalLineasNegocios.title = item_nombre;

      // Se busan las Líneas de negocio relacionadas el item de inspección
      axios
        .get("/api/admin/lineasNegocios/lista", {
          params: {
            secciones_has_items_inspeccion_id,
          },
        })
        .then((response) => {
          this.modalLineasNegocios.lineas_negocio = response.data;
        });
    },
    llenarSelectItems() {
      var url = "api/admin/itemsInspeccion/lista?filtro=";
      let me = this;
      axios
        .get(url)
        .then(function(response) {
          let respuesta = response.data;
          me.arrayitems = respuesta.items;
          me.nombre_Item = me.arrayitems.find(
            (items) => items.id === me.idItem
          );
        })
        .catch(function(error) {
          this.$swal({
            icon: "error",
            title: "Ocurrio un error" + error,
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          });
        });
    },
    limpiarModalItems() {
      this.arrayrelevantelineas = [];
      this.relevante = "";
      this.Sub_calificacion = {
        mantenimiento: false,
        hse: false,
      };
    },
    subirOrden(index) {
      let orden = index + 1;
      const item_actual = this.itemsSeccion[index].pivot;
      const item_anterior = this.itemsSeccion[index - 1].pivot;
      // Se ajusta el arreglo para actualizar el orden
      const params = [];
      params.push({
        seccion_id: item_actual.seccion_id,
        item_id: item_actual.item_id,
        orden: orden - 1,
      });
      params.push({
        seccion_id: item_anterior.seccion_id,
        item_id: item_anterior.item_id,
        orden: orden,
      });
      this.actualizaOrden(params);
    },
    bajarOrden(index) {
      let orden = index + 1;
      const item_actual = this.itemsSeccion[index].pivot;
      const item_siguiente = this.itemsSeccion[index + 1].pivot;
      // Se ajusta el arreglo para actualizar el orden
      const params = [];
      params.push({
        seccion_id: item_actual.seccion_id,
        item_id: item_actual.item_id,
        orden: orden + 1,
      });
      params.push({
        seccion_id: item_siguiente.seccion_id,
        item_id: item_siguiente.item_id,
        orden: orden,
      });
      this.actualizaOrden(params);
    },
    actualizaOrden(params) {
      axios({
        method: "PUT",
        url: "/api/admin/seccionInspeccion/ordenarItems",
        data: params,
      })
        .then(() => {
          this.loadItems(this.activesection);
          this.$swal({
            icon: "success",
            title: "Se ordenó el Item de Inspección de manera correcta",
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          });
        })
        .catch((e) => {
          this.$swal({
            icon: "error",
            title: "Ocurrió un error, vuelva a intentarlo... " + e,
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          });
        });
    },
  },
  mounted() {
    this.init();
    this.LoadSections();
    this.getEstados();
    this.getLineasNegocio();
  },
};
</script>
